import Table from './Table';
import Search from './Search';
import Time from './Time';
import { useContext, useEffect } from 'react';
import { TableContext } from '../context';

function Home() {
  const { graphData, tableData, setTableData } = useContext(TableContext);
  return (
    <div className='home'>
      <h2 style={{padding: '1.2rem 2.5rem 1.0rem', fontWeight: '500'}}>Search For Application</h2>
      <div className='search' style={{display: 'flex', justifyContent: 'space-between', margin: '0 3.5rem'}}>
          <Search />
          <Time />
      </div>
      {tableData?.length > 0 ? <Table
                Header1="Change Number"
                Header2="Configuration Item"
                Header3="Risk"
                Header4="Short Description"
                Header5="Planned Start Date"
                Header6="Planned End Date"
                Header7="State"
                Header8="Type"
                Header9="Assignment Group"
                Header10="Requested By"
                Header11="Close Code"
                // Header12="Category"
              /> : ""}
    </div>
  );
}

export default Home;