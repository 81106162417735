import React, { useContext, useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TableContext } from '../context';

export default function Time() {
    const { selectedTime, setSelectedTime, setLoading } = useContext(TableContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [startDate, setStartDate] = useState(DateTime.now());
    const [endDate, setEndDate] = useState(DateTime.now());
    const [temporaryDatePickerTime, setTemporaryDatePickerTime] = useState({start: DateTime.now(), end: DateTime.now()});
    const [temporaryTimeOptionTime, setTemporaryTimeOptionTime] = useState({start: DateTime.now(), end: DateTime.now()});
    const [showSetButton, setShowSetButton] = useState(false);
    const [lastChanged, setLastChanged] = useState('');

    useEffect(() => {
        console.log('temporaryDatePickerTime:', temporaryDatePickerTime);
        console.log('temporaryTimeOptionTime:', temporaryTimeOptionTime);
        console.log('lastChanged:', lastChanged);
    }, [temporaryDatePickerTime, temporaryTimeOptionTime, lastChanged]);

    const handleDateChange = () => {
        const utcStartDate = startDate.toJSDate();
        const utcEndDate = endDate.toJSDate();
        setTemporaryDatePickerTime({ start: startDate.toISO(), end: endDate.toISO() });
        setSelectedTime({ start: utcStartDate, end: utcEndDate });
        setLoading(true);
        setIsDropdownOpen(false);
        setShowSetButton(false);
        setLastChanged('datePicker');
    };

    const handleTimeOptionClick = (start, end) => {
        const utcStartDate = DateTime.fromISO(start, { zone: 'UTC' }).toJSDate();
        const utcEndDate = DateTime.fromISO(end, { zone: 'UTC' }).toJSDate();
        setTemporaryTimeOptionTime({ start, end });
        setSelectedTime({ start: utcStartDate, end: utcEndDate });
        setLoading(true);
        setIsDropdownOpen(false);
        setLastChanged('timeOption');
    };

    const timeOptions = [
        { label: "Current Time", start: DateTime.now().toISO(), end: DateTime.now().toISO() },
        { label: "Last 5 mins", start: DateTime.now().minus({ minutes: 5 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 10 mins", start: DateTime.now().minus({ minutes: 10 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 30 mins", start: DateTime.now().minus({ minutes: 30 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last hour", start: DateTime.now().minus({ hours: 1 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 2 hours", start: DateTime.now().minus({ hours: 2 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 3 hours", start: DateTime.now().minus({ hours: 3 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 4 hours", start: DateTime.now().minus({ hours: 4 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 6 hours", start: DateTime.now().minus({ hours: 6 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 12 hours", start: DateTime.now().minus({ hours: 12 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last day", start: DateTime.now().minus({ days: 1 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 2 days", start: DateTime.now().minus({ days: 2 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 3 days", start: DateTime.now().minus({ days: 3 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 4 days", start: DateTime.now().minus({ days: 4 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 5 days", start: DateTime.now().minus({ days: 5 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last week", start: DateTime.now().minus({ weeks: 1 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last 2 weeks", start: DateTime.now().minus({ weeks: 2 }).toISO(), end: DateTime.now().toISO() },
        { label: "Last month", start: DateTime.now().minus({ months: 1 }).toISO(), end: DateTime.now().toISO() },
        { label: "Upcoming 5 mins", start: DateTime.now().toISO(), end: DateTime.now().plus({ minutes: 5 }).toISO() },
        { label: "Upcoming 10 mins", start: DateTime.now().toISO(), end: DateTime.now().plus({ minutes: 10 }).toISO() },
        { label: "Upcoming 30 mins", start: DateTime.now().toISO(), end: DateTime.now().plus({ minutes: 30 }).toISO() },
        { label: "Upcoming hour", start: DateTime.now().toISO(), end: DateTime.now().plus({ hours: 1 }).toISO() },
        { label: "Upcoming 2 hours", start: DateTime.now().toISO(), end: DateTime.now().plus({ hours: 2 }).toISO() },
        { label: "Upcoming 3 hours", start: DateTime.now().toISO(), end: DateTime.now().plus({ hours: 3 }).toISO() },
        { label: "Upcoming 4 hours", start: DateTime.now().toISO(), end: DateTime.now().plus({ hours: 4 }).toISO() },
        { label: "Upcoming 6 hours", start: DateTime.now().toISO(), end: DateTime.now().plus({ hours: 6 }).toISO() },
        { label: "Upcoming 12 hours", start: DateTime.now().toISO(), end: DateTime.now().plus({ hours: 12 }).toISO() },
        { label: "Upcoming day", start: DateTime.now().toISO(), end: DateTime.now().plus({ days: 1 }).toISO() },
        { label: "Upcoming 2 days", start: DateTime.now().toISO(), end: DateTime.now().plus({ days: 2 }).toISO() },
        { label: "Upcoming 3 days", start: DateTime.now().toISO(), end: DateTime.now().plus({ days: 3 }).toISO() },
        { label: "Upcoming 4 days", start: DateTime.now().toISO(), end: DateTime.now().plus({ days: 4 }).toISO() },
        { label: "Upcoming 5 days", start: DateTime.now().toISO(), end: DateTime.now().plus({ days: 5 }).toISO() },
        { label: "Upcoming week", start: DateTime.now().toISO(), end: DateTime.now().plus({ weeks: 1 }).toISO() },
        { label: "Upcoming 2 weeks", start: DateTime.now().toISO(), end: DateTime.now().plus({ weeks: 2 }).toISO() },
        { label: "Upcoming month", start: DateTime.now().toISO(), end: DateTime.now().plus({ months: 1 }).toISO() },
    ];

    const displayTime = lastChanged === 'datePicker' ? temporaryDatePickerTime : temporaryTimeOptionTime;

    return (
        <div style={{ position: 'relative', width: 'fit-content' }}>
            <div 
                style={{ 
                    width: '28vw', 
                    height: "fit-content", 
                    display: 'flex', 
                    alignItems: 'center', 
                    cursor: 'pointer', 
                    justifyContent: 'space-between', 
                    border: '1px solid black',
                    padding: '10px',
                    borderRadius: '5px' 
                }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                <span style={{fontSize: "0.83rem"}}> Time: {DateTime.fromISO(displayTime.start, {zone: "UTC"}).toFormat('dd-MM-yyyy HH:mm:ss')} UTC - {DateTime.fromISO(displayTime.end, {zone: "UTC"}).toFormat('dd-MM-yyyy HH:mm:ss')} UTC</span>
                <span style={{ 
                    marginLeft: 'auto', 
                    paddingLeft: '10px', 
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', 
                    transition: 'transform 0.3s ease',
                    color: '#42b0d5' 
                }}>▼</span>
            </div>

            {isDropdownOpen && (
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    width: "100%",
                    justifyContent: 'space-between',
                    height: '25vh',
                    flexDirection: 'column'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
                        <div style={{ marginRight: '10px', padding: '5px', width: "60%", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <>
                                    <div style={{ marginBottom: '10px' }}>
                                        <label style={{fontSize: '0.8rem'}}>Start Date</label>
                                        <DateTimePicker
                                            ampm={false}
                                            value={DateTime.fromISO(displayTime.start, {zone: "UTC"})}
                                            onChange={(date) => {
                                                if (date) {
                                                    setStartDate(date);
                                                    setShowSetButton(date && endDate);
                                                }
                                            }}
                                            textField={(params) => (
                                                <input 
                                                    {...params} 
                                                    value={startDate ? startDate.toFormat('dd-MM-yyyy HH:mm:ss') : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label style={{fontSize: '0.8rem'}}>End Date</label>
                                        <DateTimePicker
                                            ampm={false}
                                            value={DateTime.fromISO(displayTime.end, {zone: "UTC"})}
                                            onChange={(date) => {
                                                if (date) {
                                                    setEndDate(date);
                                                    setShowSetButton(startDate && date);
                                                }
                                            }}
                                            minDateTime={startDate}
                                            maxDateTime={startDate.plus({ days: 30 })}
                                            textField={(params) => (
                                                <input 
                                                    {...params} 
                                                    value={endDate ? endDate.toFormat('dd-MM-yyyy HH:mm:ss') : ''}
                                                />
                                            )}
                                        />
                                    </div>
                                </>
                            </LocalizationProvider>
                            {showSetButton && (
                                <button 
                                    style={{
                                        marginTop: '10px', 
                                        padding: '5px 25px', 
                                        cursor: 'pointer', 
                                        backgroundColor: 'rgb(66, 176, 213)', 
                                        color: 'white', 
                                        border: 'none', 
                                        borderRadius: '5px'
                                    }}
                                    onClick={handleDateChange}
                                >
                                    Set Time
                                </button>
                            )}
                        </div>

                        <div style={{ width: '1px', backgroundColor: '#ddd', margin: '10px' }}></div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: "40%", overflowY: 'auto' }}>
                            {timeOptions.map((option, index) => (
                                <div 
                                    key={index}
                                    style={{ padding: '5px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
                                    onClick={() => handleTimeOptionClick(option.start, option.end)}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}