import React, { useState, useContext, useEffect, useRef } from 'react';
import { TableContext } from '../context';
import '../App.css';
import { FaTimes } from 'react-icons/fa'; // Import the "X" icon from react-icons

/**
 * Debounce function to delay the execution of a function.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The delay in milliseconds.
 */
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

/**
 * Search component for searching application names.
 * Provides suggestions based on user input and allows fetching table data.
 */
function Search() {
  const { setSearch } = useContext(TableContext);
  const [inputValue, setInputValue] = useState(''); // State to track entire input value
  const [suggestions, setSuggestions] = useState([]);
  const [currentAppName, setCurrentAppName] = useState(''); // Track current application being typed
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const latestRequestRef = useRef(0); // Ref to track the latest request

  /**
   * Handles the change of the input value.
   * Update the input value and check if the user has typed a comma.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Extract the current application name after the last comma
    const lastCommaIndex = value.lastIndexOf(',');
    const currentApp = value.slice(lastCommaIndex + 1).trim();
    
    setCurrentAppName(currentApp);
    setShouldFetchSuggestions(true); // Allow fetching suggestions again if there's input
  };

  /**
   * Handles the click event of the search button.
   * Pass the entire input value (comma-separated application names) to search.
   * Also trims any trailing commas from the input before passing it.
   */
  const handleButtonClick = () => {
    // Remove trailing comma and any extra spaces before setting the search
    const cleanedInput = inputValue.trim().replace(/,\s*$/, '');
    setSearch(cleanedInput); // Pass the cleaned comma-separated input value
  };

  /**
   * Handles the click event of a suggestion.
   * Adds the selected suggestion to the input, clears suggestions, and prepares for the next app name.
   * @param {string} suggestion - The selected suggestion.
   */
  const handleSuggestionClick = (suggestion) => {
    // Append the selected suggestion to the input, followed by a comma
    const lastCommaIndex = inputValue.lastIndexOf(',');
    const newValue = lastCommaIndex !== -1
      ? `${inputValue.slice(0, lastCommaIndex + 1)} ${suggestion}, `
      : `${suggestion}, `;
      
    setInputValue(newValue);
    setCurrentAppName(''); // Reset current app name
    setSuggestions([]); // Clear suggestions
    setShouldFetchSuggestions(false); // Stop fetching suggestions until typing resumes
  };

  /**
   * Handles the click event of the "X" icon.
   * Clears the input value and suggestions.
   */
  const handleClearClick = () => {
    setInputValue('');
    setCurrentAppName('');
    setSearch(''); // Clear search value
    setSuggestions([]); // Clear suggestions
    setShouldFetchSuggestions(false); // Stop fetching suggestions
  };

  useEffect(() => {
    /**
     * Fetches suggestions based on the current application name being typed.
     * @param {string} currentAppName - The current application name being typed.
     */
    const fetchSuggestions = async (currentAppName) => {
      if(currentAppName.length >= 3 || !shouldFetchSuggestions) {
        const requestId = Date.now();
        latestRequestRef.current = requestId;
        setIsLoading(true); // Set loading to true before fetching suggestions

        const url = /^[Aa]\d{2,}$/.test(currentAppName)
          ? 'https://flux-backend.azurewebsites.net/api/change_data/getAppIDSuggestions'
          : 'https://flux-backend.azurewebsites.net/api/change_data/getAppNameSuggestions';

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + localStorage.getItem("jwt_token"),
            },
            body: JSON.stringify({ app_name: currentAppName }),
          });

          const data = await response.json();
          if (latestRequestRef.current === requestId) {
            if (data.status === 200) {
              setSuggestions(data.data || []);
            } else {
              setSuggestions([]);
            }
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          if (latestRequestRef.current === requestId) {
            setSuggestions([]);
          }
        } finally {
          if (latestRequestRef.current === requestId) {
            setIsLoading(false); // Set loading to false after fetching suggestions
          }
        }
      }
    };

    const debouncedFetchSuggestions = debounce(() => fetchSuggestions(currentAppName), 300);
    debouncedFetchSuggestions();
  }, [currentAppName, shouldFetchSuggestions]);

  return (
    <div className="search-container">
      <div className="search-bar">
        <h3 className="search-label">Application Names:</h3>
        <div className='search-Input_suggestions'>
          <input
            className="search-input"
            type="text"
            placeholder="Search for applications"
            value={inputValue}
            onChange={handleInputChange}
          />
          {inputValue && (
            <FaTimes 
              className="clear-icon" 
              onClick={handleClearClick} 
            />
          )}
          {(isLoading && currentAppName.length >= 3) ? (
            <ul className="suggestions-dropdown">
              <li className="suggestion-item">Loading...</li>
            </ul>
          ) : (
            (suggestions.length > 0 && currentAppName.length >= 3) && (
              <ul className="suggestions-dropdown">
                {suggestions.map((suggestion, index) => (
                  <li 
                    key={index} 
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )
          )}
        </div>
        <div className="tooltip-container">
          <button 
            onClick={handleButtonClick} 
            className="animated-search-button"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default Search;