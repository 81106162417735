import './App.css';
import Navbar from './components/Navbar';
import React, { useState, useContext, useEffect } from 'react';
import Home from './components/Home';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { TableContext } from "./context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from './components/Loader'; // Import the Loader component
import Footer from './components/Footer';

/**
 * Main application component.
 * Handles authentication, routing, and global state management.
 */
function App() {
  // Initiate MSAL authentication using redirect interaction type
  useMsalAuthentication(InteractionType.Redirect);

  // Extract context values from TableContext
  const { graphData, setGraphData, tokenFetched, setTokenFetched, getToken, getTableData, fetchUserDetails, loading } = useContext(TableContext);
  
  // Local state variables
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [run, setRun] = useState(false);

  /**
   * Closes the modal.
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Opens the modal.
   */
  const handleOpen = () => {
    setOpen(true);
  };

  // Effect to fetch token when graphData is available
  useEffect(() => {
    if (graphData?.displayName !== undefined && graphData?.displayName !== null) {
      getToken();
      // console.log("JWT Token ", localStorage.getItem("jwt_token"));
    }
  }, [graphData]);

  // Effect to fetch table data when token is fetched
  useEffect(() => {
    if (tokenFetched) {
      // console.log("Token fetched, calling getTableData");
      getTableData();
    }
  }, [tokenFetched]);// called when tokenFetched changes

  return (
    <>
      {loading && <Loader />} {/* Display loader when loading is true */}
      <AuthenticatedTemplate>
        <Router>
          <ToastContainer
            position="top-center" // Set the position of the toast container
            autoClose={3000} // Automatically close toasts after 3 seconds
            hideProgressBar={false} // Display the progress bar
            closeOnClick={true} // Close toasts on click
            pauseOnHover={true} // Pause toast animation on hover
            draggable={true}
            closeButton={true} // Allow toasts to be dragged
            theme="light" // Use the light theme
          />

          <Navbar setRun={setRun} run={run} graphData={graphData} />
          <Routes>
            <Route
              exact
              path="/"
              element={<Home setProgress={setProgress} graphData={graphData} setOpen={setOpen} setRun={setRun} run={run} />}
            />
          </Routes>
        </Router>
        <Footer />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <h1>Authenticating...</h1>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;